// slick-theme doesn't have a variables file we can import, so we copied the variables we need from `slick-theme.scss`
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

// Our custom styles:

$size: 80px;

/* Arrows */

.exercisesContainer .slick-prev,
.exercisesContainer .slick-next {
  position: absolute;
  display: block;
  height: $size;
  width: $size / 2;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: rgba(0,0,0,0.5);
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background: rgba(0,0,0,0.8);
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 40px;
    line-height: 1;
    color: #FFF;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.exercisesContainer .slick-prev {
  left: 0.7rem;
  border-bottom-right-radius: $size * 2;
  border-top-right-radius: $size * 2;
  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }
  &:before {
    content: $slick-prev-character;
    margin-left: -8px;

    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.exercisesContainer .slick-next {
  right: 0.7rem;
  border-bottom-left-radius: $size * 2;
  border-top-left-radius: $size * 2;

  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    margin-right: -8px;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}
