.side-nav {

  a {
    color: rgba(0,0,0,0.87);
    display: block;
    font-size: 14px;
    font-weight: 700;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
  }

  .subheader {
    cursor: initial;
    pointer-events: none;
    color: rgba(0,0,0,0.54);
    font-size: 14px;
    font-weight: 700;
    line-height: 48px;
  }

  .userView {
    position: relative;
    padding: 32px 32px 0;
    margin-bottom: 8px;

    .background {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      &:after {
        height: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        content: '';
        display: block;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.3+100 */
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }

      img {
        width: 100%;
      }
    }

    &>a {
      height: auto;
      padding: 0;
    }

    .circle {
      height: 64px;
      width: 64px;
    }

    .circle, .name, .email {
      display: block;
      font-size: 14px;
      line-height: 24px;
    }
    .name {
      margin-top: 16px;
      font-weight: 700;
    }
    .email {
      padding-bottom: 16px;
      font-weight: 400;
    }
  }

  li>a {
    &>i, &>[class^="mdi-"],&>[class*="mdi-"],&>i.material-icons {
      float: left;
      height: 48px;
      line-height: 48px;
      margin: 0 32px 0 0;
      width: 24px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}