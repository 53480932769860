// Fix color picker input heights, which didn't look correct with Materialize based styling.
.sketch-picker {
  input {
    height: auto;
    margin-bottom: auto;
  }
}

.tabs {
  display: block;
  .tab {
    display: inline-block;
    text-align: center;
    line-height: 48px;
    height: 48px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;

    a {
      color: $off-black;
      border-bottom: 2px solid #FFF;
      font-weight: 700;

      &:hover {
        color: $dark-grey;
      }

      &.active {
        border-bottom: 2px solid $primary-color;
      }
    }

  }
  .indicator {
    display: none;
  }
}

.card {
  &.bordered {
    box-shadow: none;
    border: 1px solid $medium-grey;
  }
  &.infoCard {
    .card-title {
      font-weight: 700;
      display: block;
      line-height: 32px;

      &.small {
        font-size: 18px;
        line-height: 24px;
        margin-top: 8px;
      }
    }
    .card-subtitle {
      color: $medium-grey;
      display: block;
      line-height: 24px;
      margin: 8px 0 12px;
    }
    .card-content {
      min-height: 167px;
    }
  }
  .inlineImage {
    vertical-align: text-bottom;
    margin-top: -8px;
  }
}

.smallGreyTitle {
  font-size: 18px;
  color: $medium-grey;
  text-transform: uppercase;
}

.small {
  font-size: 18px;
}

.smallLink {
  font-size: 11px;
  line-height: 13px;
  color: $medium-grey;
  display: inline-block;
}

.row.rowItem {
  margin: 16px -12px;
}

.userOverviewImg {
  max-width: 32px;
  border-radius: 100%
}

.upgradePage {
  #page {
    margin-top: -340px;
    z-index: 2;
    position: relative;

    @media #{$small-and-down} {
      margin-top: -240px;
    }
  }
  @media #{$small-and-down} {
    .loginHeader .transparent {
      z-index: 1000;
      position: relative;
    }
  }
}

.upgradeBlock-container {
  @media #{$large-and-up} {
    display: flex;
    justify-content: center;
  }
}

.upgradeCard-container {
  margin-left: unset !important;
}

.upgradeCard {
  background-color: $white;
  padding: 20px 24px 24px;
  position: relative;
  border-top: 4px solid $white;
  box-shadow: 0px 0px 4px rgba($off-black,0.3);
  margin-bottom: 32px;

  &.active {
    border-top: 4px solid $primary-color;
    box-shadow: 0px 0px 24px rgba($off-black,0.3);
  }

  .upgradeTitle, .upgradeSubtitle {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
  }

  .upgradeSubtitle {
    color: $medium-grey;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    display: block;
    margin-top: -4px;
  }

  ul.checkedList {
    padding-left: 24px;
    margin: 24px 0;

    li {
      margin-bottom: 8px;
      position: relative;

      &:before {
        display: block;
        content: '✔';
        color: $off-black;
        position: absolute;
        left: -24px;
        top: 0;
      }
    }
  }

  .upgradeTag {
    background-color: $primary-color;
    position: absolute;
    top: 0;
    font-size: 12px;
    text-transform: uppercase;
    left: 50%;
    padding: 4px 1rem;
    transform: translate(-50%,-50%);
  }



  .upgradePriceSubtitle {
    color: #ff9800;
    display: block;
    text-align: center;
    font-size: 12px;
  }


  .upgradeButtonContainer {
    text-align: center;
    margin-top: 24px;
  }
}

.upgradePrice {
  display: block;
  font-size: 48px;
  line-height: 48px;

  .valuta {
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
  }
}

.inlineModal  {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .modalContentContainer {
    background-color: #F8F8F8;
    box-shadow: 0px 0px 24px rgba($off-black,0.3);
    padding: 40px;

    &.upgrade-state {
      padding: 20px 40px 40px 40px;

      a {
        margin-top: 20px;
      }
    }

    @media #{$small-and-down} {
      padding: 24px;
    }
  }
}

#invoiceFormContainer {
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

#pricesContainer {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

#page.invoice {
  #pricesContainer {
    opacity: 0;
  }

  #invoiceFormContainer {
    display: block;
    opacity: 1;
  }
}

.imageContainer {
  img {
    max-width: 100%;
  }
}
