$small: 600px;
$large: 1500px;

.splitcolumnPage {
  background-color: $off-white;

  & > .row {
    position: relative;
  }

  .greyContainer {
    background-color: $secondary-color;
    width: 100%;
    color: $white;
    min-height: 100vh;
    height: 100%;
    position: fixed;
    right: 0 !important;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;

    padding: 0;
    padding-top: 24px;

    /* In $small it should be 20px and in $large it should be 100px,  */
    /* In viewports between that its padding should be calculated */
    @media (min-width: $small) {
      padding: between(0, 24px, $small, $large);
      padding-top: 32px;
    }

    /* In $large we cap the value at 100px */
    @media (min-width: $large) {
      padding: 24px;
      padding-top: 32px;
    }

    /*/////// typography */

    .profilePic {
      width: 48px;
      vertical-align: middle;
      margin-right: 12px;
    }

    h6 {
      font-size: 20px;

      &.profileName {
        display: inline;
      }
    }

    hr {
      border: none;
      border-bottom: 1px solid $medium-grey;
      margin: 20px 0 12px;
    }

    @media #{$small-and-down} {
      top: calc(100% - 64px);
      box-shadow: -1px -1px 8px rgba(0,0,0,0.5);
      padding-top: 8px;
      z-index: 9;
      transition: top 0.2s ease-in-out;

      a.menuLink {
        color: $white;
        line-height: 20px;
        display: inline-block;
        margin-top: 12px;

        i {
          vertical-align: middle;
        }

        &.close {
          display: none;
        }
      }

      hr {
        margin: 12px 0 8px;
      }

      &.open {
        top: 0;

        a.menuLink {
          display: none;

          &.close {
            display: inline-block;
          }
        }
      }
    }

  }

  input#startDate {
    display: none;
  }
}

.schemeInfo {
  h5 {
    margin-bottom: 4px;
  }
  .schemeDate {
    color: $medium-grey;
  }
}

.exerciseListItem {
  background-color: $darker-grey;
  padding: 8px 16px 2px 8px;
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &.empty {
    border: 1px dashed $medium-grey;
    color: $medium-grey;
    background-color: transparent;
    .itemTitle {
      font-size: 16px;
    }
  }

  &:after {
    clear: both;
    display: block;
    content: '';
  }

  .itemTitle {
    flex-grow: 4;
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 20px;
    line-height: 24px;
  }

  .dragIcon {
    color: $medium-grey;
    font-size: 12px;
    cursor: move;

    i {
      font-size: 1.4rem;
    }
  }

  &:hover {
    .dragIcon {
      display: inline-block;
    }
  }


  .infoSetting {
    margin-left: 16px;

    .infoName {
      font-size: 12px;
    }

    .tooltipped {
      color: $white;
      &.afterSwitch {
        display: inline;
      }
    }

    .infoValue {
      min-width: 40px;
      background-color: $white;
      color: $off-black;
      padding: 0 4px;
      border-radius: 8px;
      display: inline-block;
      text-align: center;
      margin: 0 0 4px 8px;
      cursor: pointer;

      &.inactive {
        color: $medium-grey;
      }
    }
  }

  .deleteButton {
    color: $light-grey;
    position: absolute;
    left: -20px;
    min-height: 40px;
    padding-top: 10px;
    cursor: pointer;

    &:hover {
      color: red;
    }

    i {
      font-size: 1.2rem;
    }

    @media #{$small-and-down} {
      position: static;
      margin-left: 12px;
    }
  }

}

.schemeHeader {
  margin-top: 16px;

  img {
    max-width: 211px;
    margin-top: 16px;

    @media #{$small-and-down} {
      max-width: none;
      margin-bottom: 16px;
    }
  }

  .goalInfo {
    color: $medium-grey;

    & + .btn {
      margin-left: 16px;
    }
  }
}

.card.exercise {
  .card-image {
    .exerciseImageContainer {
      background-size: cover;
      //height: 168px;
      height: 264px;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .trainingTemplateImageContainer {
      background-size: cover;
      height: 132px;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .addAction {
    position: relative;
    margin-bottom: 48px;

    .btn-floating {
      position: absolute;
      top:0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .cardInfo {
    text-align: center;
    min-height: 112px;

    .card-title {
      display: block;
      font-size: 24px;
    }

    .extraInfo {
      color: $medium-grey;
      font-size: 14px;
    }
  }

  .card-action {
    padding: 0;

    a {
      display: block;
      padding: 16px 24px;
      background-color: $light-grey;
      color: $dark-grey !important;
      text-align: center;
      margin-right: 0 !important;
      font-size: 14px;
      text-transform: none;
    }
  }
}

.oneRMFormContainer {
  position: absolute;
  background-color: $dark-grey;
  padding: 8px;
  width: 208px;
  right: 0;
  bottom: -64px;
  display: none;
  z-index: 999;

  input[type=text] {
    display: inline-block;
    width: 30px;
    height: 32px;
    border: none;
    background-color: $white;
    color: #000;
    margin: 0 8px;
    padding: 0 2px;
    text-align: center;
    border: 2px solid $white;
    border-top: 1px solid $white;
  }

  &:after {
    display: block;
    content: '';
    width:0;
    height: 0;
    position: absolute;
    right: 28px;
    top: -16px;
    border: 8px solid transparent;
    border-bottom: 8px solid $dark-grey;
  }
  .btn.grey {
    margin-right: 8px;
  }
}
.openSetter .oneRMFormContainer {
  display: block;
}

body > li.fmfSortable {
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;

  .exerciseListItem {
    transform: translateY(-16px);
    opacity: 0.8;
  }
}

.trainingScheduleDetail {
  .pageheader {
    h1 {
      font-size: 32px;
    }
  }
}

@media print {
  .printMargin {
    border-top: 1cm solid rgba(0,0,0,0);
  }
}

.detailCard {
  background-color: $white;
  box-shadow: 1px 2px 2px rgba(0,0,0,0.2);
  margin-bottom: 16px;
  border-top: 4px solid $primary-color;

  &.print {
    background-color: transparent;
    box-shadow: none;
    page-break-inside: avoid;
    margin-bottom: 32px;
  }

  @media #{$medium-and-up} {
    .row .col.s12.m3 {
      padding-right: 0;
    }
  }

  .row.collapsed > .col {
    padding: 0;
  }

  .sliderContainer {
    padding: 0;
    margin-right: -1px;

    .slick-dotted.slick-slider {
      margin: 0;
    }
    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .infoContainer {
    padding: 32px 16px 8px;
    h5 {
      font-size: 24px;
      margin: 0;
    }

    &.print {
      padding: 12px 0;
    }

    @media #{$small-and-down} {
      padding: 16px 16px 8px;
    }
  }

  .imageRow {
    img {
      max-width: 33%;
      display: inline;
      margin-right: 0.5%;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .dataContainer {
    padding: 0 16px;

    &.print {
      padding: 0;
      margin-bottom: 16px;
    }
  }

  .printImage {
    max-width: 100%;
  }

  .printColumn {
    padding: 0;

    p {
      padding-right: 8px;
    }
  }

  .buttonContainer {
    padding: 32px 16px 8px 0;
    text-align: right;

    .btn {
      padding: 0 0.6rem;
    }

    .btn + .btn {
      margin-left: 8px;
    }

    .btn.grey {
      background-color: $light-grey !important;
      color: $dark-grey;
    }

    @media #{$small-and-down} {
      padding: 0 16px 12px;

    }

    .oneRMContainer {
      display: inline;
      position: relative;
      margin-left: 8px;

      a > a.tooltipped {
        color: inherit;
      }
    }

    .preloader-wrapper.small {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin-right: 8px;
    }
    &.hideLoader .preloader-wrapper.small {
      display: none;
    }
  }
}

$tableLineStyle: 1px dashed $light-grey;

.exerciseDataTable {
  margin: 0 !important;

  @media #{$small-and-down} {
    margin-bottom: 16px !important;
  }

  .col {
    padding: 0;

    &:first-child > .row {
      @media #{$small-and-down} {
        .col {
          position: relative;

          &:after {
            position: absolute;
            display: block;
            content: attr(data-week);
            color: $medium-grey;
            font-size: 12px;
            width: 8;
            height: 40px;
            line-height: 40px;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .row {
    margin: 0;



    .col {
      border-top: $tableLineStyle;
      border-left: $tableLineStyle;

      &:first-child {
        @media #{$medium-and-up} {
          border-left: none;
        }
      }

      &:last-child {
        border-right: $tableLineStyle;
      }

    }

    &:first-child {
      @media #{$small-and-down} {
        .col {
          border-left: none;
          border-right: $tableLineStyle;
        }
      }
    }


  }

  .tableValue {
    min-height: 40px;
    text-align: center;
    line-height: 40px;
    white-space: nowrap;
    color: $off-black;

    &.variableName {
      color: $medium-grey;
    }

    &.dataLeft {
      overflow: hidden;
      text-overflow: ellipsis;
      @media #{$medium-and-up} {
        text-align: left;
      }
    }

    &.empty {
      background-color: #eee;
      -webkit-print-color-adjust: exact;
    }

    input {
      width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      height: 40px;
      text-align: center;

      &:focus {
        border: none;
      }
    }
  }

  @media #{$small-and-down} {
    .variableName {
      font-size: 12px;
    }
  }

  .weeks {
    .variableName {
      font-size: 12px;
    }
  }
}

.pageheader.schedule {
  img {
    @media #{$medium-and-up} {
      margin-top: 32px;
    }
    @media #{$small-and-down} {
      margin-bottom: 16px;
    }
  }
  h1 {
    @media #{$small-and-down} {
      margin-bottom: 8px;
    }
  }
}

.resetFilters {
  margin-right: 12px;
}

.select-dropdown.active:focus {
  transform: scale(0);
}