.goalInfo.tooltipped {
  vertical-align: -webkit-baseline-middle;
}
.variableName {
  color: $medium-grey;
}
.variableNameLink {
  display: inline-block;
  margin-left: 8px;

  i {
    font-size: 20px;
    vertical-align: bottom;
  }
}

.sliderTrackContainer {
  padding: 0 16px;
  margin-top: 4px;
  position: relative;
}

.noUi-rtl {
  .noUi-origin {
    left: auto;
    transform: translateX(100%);
    z-index: 1000;

    .noUi-horizontal .noUi-handle {
      left: 8px;
    }
  }

}

.restoreButton {
  display: inline-block;
  color: $primary-color;
  transform: translateY(6px);
  opacity: 0.8;

  &:hover {
    color: $dark-grey;
    opacity: 1;
  }
}

.sliderContainer {
  padding: 4px 0;
  .row {
    .col {
      padding: 0;
    }
  }

  .noUi-connect {
    background: $primary-color;
    box-shadow: none;
    height: 16px;
    position: absolute;
  }
}
