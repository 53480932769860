#exerciseInfo {

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  width: 1000px;
  max-width: 100%;
  max-height: none;
  top: 50% !important;
  transform: translateY(-50%) !important;
  overflow-y: hidden;

  .exerciseInfo {
    height: 700px;

    .sliderContainer {
      width: 466px;
      max-width: 47%;
      height: 700px;
      float: left;
      padding: 0;

      .slick-slide img {
        width: 100%;
      }
    }

    .infoContainer {
      float: left;
      width: 534px;
      max-width: 53%;
      height: 700px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 24px 32px 56px;

      .extraInfo {
        color: $dark-grey;
      }

      h6 {
        font-weight: 700;
      }
    }

    .closeButton {
      position: absolute;
      right: 16px;
      top: 16px;
      color: #000;
      cursor: pointer;
    }

    @media #{$small-and-down} {
      .sliderContainer, .infoContainer {
        width: 100%;
        max-width: none;
        height: auto;
        float: none;
      }

      .infoContainer {
        position: relative;
        overflow-x: visible;
        overflow-y: visible;
      }

      .closeButton {
        top: -8px;
      }
    }
  }

  @media #{$small-and-down} {
    overflow-y: auto;
  }
}

.helpLink {
  text-align: right;
  font-size: 12px;
}

@charset "UTF-8";

$slick-font-path: "./fonts/" !default;
$slick-font-family: "Material Icons" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: black !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "keyboard_arrow_left" !default;
$slick-next-character: "keyboard_arrow_right" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 56px;
  width: 56px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  bottom: -16px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 999;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 56px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: 4px;
  &:before {
    content: $slick-prev-character;
  }
}

.slick-next {
  right: 4px;
  &:before {
    content: $slick-next-character;
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 24px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: $medium-grey;
      display: block;
      height: 32px;
      min-width:32px;
      outline: none;
      line-height: 16px;
      font-size: 16px;
      color: $white;
      padding: 8px;
      cursor: pointer;
      white-space: nowrap;
      text-align: center;
      border-radius: 16px;

      .step {
        display: none;
      }

      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
    }
    &.slick-active button {
      background-color: $dark-grey;

      .step {
        display: inline;
      }
    }
  }
}