.clientDashboard {

  .notificationHeader {
    position: relative;
    top:-28px;
    left:0px;
    right:0px;
    height:70px;
    line-height:70px;
    background:$primary-color;
    clear:both;

    .container {
      line-height:70px;
    }

    .row {
      margin-bottom:0px;
    }

    .notificationColumn {
      text-align:center;
    }

    .notificationText {
      margin-right:20px;
    }

    .btn-large {
      border: 1px solid black;
      border-radius: 2px;
      box-shadow: none;
      height: 44px;
      line-height: 44px;
    }
  }

  h1 {
    margin-top: 24px;
  }


  #editClientForm .row {
    margin-bottom: 0;
  }

  .inactive.row {
    .profilePic {
      opacity: 0.5;
    }
    .userInfo {
      color: $error-color;
    }
  }

  @media #{$medium-and-up} {

    .dashboardScheduleContainer {
      .row .col.last-2-col {
        clear:left;
      }
    }
  }

  @media #{$large-and-up} {
    .dashboardScheduleContainer {
      .row .col.last-2-col {
        clear:none;
      }

      .row .col.last-4-col {
        clear:left;
      }
    }
  }
}

@media #{$small-and-down} {
  .pageheader {
    text-align: center;
    h1, .userInfo {
      display: block;
    }

    .profilePic {
      display: block;
      max-width: 160px;
      margin: 0 auto;
    }

    .infoItem {
      display: block;
    }
    .infoItem + .infoItem {
      &:before {
        display: none;
      }
    }
  }

  .clientDashboard {
    .notificationHeader {
      .notificationText {
        margin-right:0px;
        display:block;
        line-height:1;
      }

      .btn-large {
        height:40px;
        line-height:40px;
        margin-top:-16px;
      }
    }
  }
}

.profilePic {
  max-width: 100%;
  border-radius: 50%;
}

.infoItem span{
  cursor: pointer;
}
.infoItem.setItem {
  color: $medium-grey;
}
.infoItem + .infoItem {
  &:before {
    content: '|';
    color: $medium-grey;
    display: inline-block;
    margin: 0 8px;
  }
}

#measurementNotPossibleTooltip {
  float: right;
  font-size: 1rem;
}

.goalContainer {
  .variableNameLink i {
      vertical-align: middle;
  }
}