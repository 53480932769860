.loaderContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 160px;

  &[data-fixed='fixed'] {
    position: fixed;
  }

  .loader {
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
  .loaderLogo {
    margin-top: 8px;
  }
}