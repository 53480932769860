.loginHeader {
  background: url('/images/loginHeader.jpg') no-repeat center center;
  background-size: cover;
  height: 48vh;
  position: relative;
  @media #{$medium-and-down} {
    height: 40vh;
  }

  &:after {
    position: absolute;
    content: '';
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    width: 100%;
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
    height: 100%;

    .loginLogoContainer {
      position: absolute;
      bottom: 56px;
      width: 100%;
      color: #FFF;

      @media #{$medium-and-down} {
        bottom: 32px;
      }

      @media #{$small-and-down} {
        bottom: 16px;
      }

      .loginLogo {
        width: 300px;
        margin-bottom: 32px;
        @media #{$small-and-down} {
          width: 200px;
          margin-bottom: 8px;
        }
      }
      h4 {
        margin: 0 0 8px;
        line-height: 40px;
        @media #{$small-and-down} {
          font-size: 24px;
          font-weight: bold;
          line-height: 28px;
        }
      }
      p {
        font-size: 22px;
        margin: 0;

        @media #{$medium-and-down} {
          font-size: 20px;
        }

        @media #{$small-and-down} {
          font-size: 15px;
        }
      }
    }
  }
}

.loginForm {
  margin-top: 40px;

  @media #{$small-and-down} {
    margin-top: 16px;
  }
}