.card.customer {
  border-top: 3px solid $primary-color;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  .card-image {
    padding-bottom: 16px;
    overflow: hidden;
    height: 250px;

    text-align: center;
    position: relative;

    img {
      border-radius: 50%;
      width: 152px;
      height: 152px;
      margin: 20px auto 12px;
    }

    .card-title {
      position: static;
      display: block;
      font-size: 24px;
      color: #000;
      padding: 0 8px;
      line-height: 28px;
    }

    .card-description {
      color: $dark-grey;
    }

    .extraInfo {
      display: block;
      color: $dark-grey;
      font-size: 14px;
      text-transform: uppercase;
      margin-top: 2px;
    }

    @media #{$small-and-down} {
      overflow: visible;
      height: 100px;
      position: relative;

      img {
        position: absolute;
        left: 8px;
        height: 96px;
        width: 96px;
        margin: 8px 0;
        z-index: 99;
      }

      .card-title {
        text-align: left;
        padding: 12px 0 8px 128px;
      }
    }
  }

  .cardIcon {
    text-align: center;
    color: $primary-color;
    padding: 16px 0;
    position: relative;
    top: 15px;
  }

  .card-action {
    background-color: #EEEEEE;
    border-top: none;

    .row {
      margin-bottom: 0;
      font-size: 14px;
      color: $dark-grey;

      .dataTags {
        text-align: right;
        .dataTag {
          font-weight: bold;
          display: inline-block;
          padding-left: 16px;
        }
      }
    }
    @media #{$small-and-down} {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &.inactive {
    border-top: 3px solid $medium-dark-grey;
    opacity: 0.5;

    .extraInfo {
      color: red !important;
    }
  }

  &.schedule {
    .card-image {
      @media #{$small-and-down} {
        height: auto;

        .card-title {
          text-align: center;
          padding: 0 8px;
        }
      }
    }
  }
}

.card.customer.schedule {
  .card-image {
    .extraInfo {
      margin-top: 12px;
    }
  }
}
