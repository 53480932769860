.note {
  .notesDetails {
    color: $medium-grey;
    margin-top: 8px;

    span {
      display: inline-block;
      margin-right: 12px;

    }

    .date {
      color: $dark-grey;
      font-weight: bold;
    }
  }

  p {
    margin-top: 4px !important;
    color: $medium-grey !important;
  }
}

h5 + .note {
  margin-top: 16px;
}

.card.note {
  .card-icon {
    color: $primary-color;
  }

  i.material-icons.tiny {
    font-size: 1.5rem;
    transform: translateY(8px);
  }
}