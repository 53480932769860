.pageheader {
  background-color: #FFFFFF;
  padding: 48px 0 24px;

  h1 {
    font-size: 38px;
    margin: 0;
    display: inline-block;
  }

  h2 {
    font-size: 31px;
    margin-bottom: 0;

    &.inactive {
      color: $dark-grey;
    }
  }

  .goalContainer h2 {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .extraInfo {
    display: inline-block;

    .specTag {
      color: $medium-dark-grey;
      padding: 0 1.5rem;

      &:first-child {
        padding-left: 3rem;
      }

      a.tooltipped i {
        vertical-align: sub;
        display: none;
      }
      a {
        display: none;
      }

      &[data-status='maxedOut'] {
       color: $error-color;
        a.tooltipped i {
          display: inline-block;
        }
        a {
          display: inline-block;
        }
      }
      &[data-status='notice'] {
        @extend .orange-text;

        a.tooltipped i {
          display: inline-block;
        }
        a {
          display: inline-block;
        }
      }
    }
  }
}

@media #{$small-and-down} {
  .pageheader {
    padding: 16px 0;

    h1 {
      font-size: 32px;
    }

    .extraInfo {
      display: block;
      margin-top: 8px;

      .specTag {
        padding: 0 1.5rem;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}