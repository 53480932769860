[data-showLoader="noLoader"] {
  .loaderContainer {
    display: none;
  }
}

[data-showLoader="loader"] {
  .loaderContainer {
    display: block;
  }
  .customers {
    display: none;
  }
}