form {
  .input-field {
    span.invalid {
      color: $error-color !important;
      top: 56px;
      font-size: 0.8rem;
      transform: none !important;
      display: block;
    }

    a.info {
      position: absolute;
      right: 8px;
      top: 16px;

      &.afterSwitch {
        vertical-align: sub;
      }
    }

    &.col input[type=checkbox] + label {
      left: 0;

      & + span.invalid {
        margin-top: 24px;
      }
    }
  }
}

a.info {

  &.afterSwitch {
    vertical-align: sub;
    transform: translateY(3px);
    display: inline-block;
  }
}

////////// ERROR MESSAGES STYLING

.card.red {
  a {
    color: $white;
    font-weight: bold;
    text-decoration: underline;
  }
}

////////// REGISTER FORM
div#registerFormContainer {
  &.showForm {
    form#registerForm {
      display: block;
    }

    div.registerSuccessNotice {
      display: none;
    }
  }

  &.showSuccess {
    form#registerForm {
      display: none;
    }

    div.registerSuccessNotice {
      display: block;
    }
  }
}