.nutritionSchedule {
    .week-navigator-row {
        .tabs {
            .btn-floating {
                background-color: $primary-color;
                height: 36px;
                width: 36px;
                margin-bottom: 6px;

                i {
                    line-height: 36px;
                }
            }
        }
    }
}

.pageheader.nutritionSchedule {
    padding-bottom:0px;
}

.week-navigator-row {
    margin-bottom:0px;
}

.nutrition-week {
    border-top:2px $primary-color solid;
    background:white;

    padding:40px 30px;
    margin-bottom:40px;

    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
}

.nutrition-week-day {
    border-top:1px #eaeaea solid;
    padding:10px 0px;
    background: white;
}

.nutrition-week-day-detail {
    border-top:2px $primary-color solid;
    padding:0px 0px;
    padding-right:30px;

    background: white;
    margin-bottom: 20px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);

    .substituteMeal i.material-icons {
        display: block;
    }
}

.dailyAdvice-title {
    color:$dark-grey;
    display: inline-block;
    margin-bottom: 20px;
}

.shoppinglist {
    h5 {
        margin-top:0px;
        margin-bottom:20px;
    }

    .shoppinglist-container {
        border-top:0px transparent none;
        padding:40px 30px;
        background: white;
        margin-bottom: 20px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    }

    .cat-row ~ .cat-row {
        padding-top: 10px;
    }

    .shoppingListItem-row {
        margin-bottom:0px;
        border-bottom: 1px #e8e8e8 solid;
        padding-bottom: 5px;

        margin-left:0px;
        margin-right:0px;

        .col {
            padding-left:0px;
            padding-right:0px;
        }

        .cat {
            font-weight: bold;
        }
    }
}

.nutritionSchedule .tabs a {
    cursor:pointer;
}

#dayDetailInfo, #mealDetailInfo, #mealSubstitutions {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    width: 1000px;
    max-width: 100%;
    max-height: none;
    top: 50% !important;
    transform: translateY(-50%) !important;
    overflow-y: hidden;
    background:white;

    .circle-loader-wrapper {
        display: block;
        height: auto;
        text-align: center;
        overflow: hidden
    }

    .mealDetailnfo {
        height: 700px;
        padding:40px;
        position:relative;
        display: flex;
        flex-direction: column;

        .tabs-container {
            position:relative;
        }

        .tabs-underline {
            position: absolute;
            bottom: 0px;
            height: 1px;
            left: 0px;
            right: 0px;
            border-bottom: 1px solid #cccccc;
            z-index:1;
        }

        .tabs {
            flex: 0 0 auto;
            background:transparent;
            z-index:2;

            .tab {
                margin-right: 24px;

                a {
                    padding-left: 0px;
                    padding-right: 0px;
                    border-bottom: 2px none transparent;
                }
            }

            .tab a.active {
                border-bottom: 2px solid $primary-color;
            }
        }

        h2 {
            font-size: 2.92rem;
            margin:0px;
            padding:0px;
            width: calc(100% - 30px);
        }
        .mealDetail-body-container {
            flex: 1 1 auto;
            overflow-y: auto;

            .cat {
                font-weight: bold;
            }
        }

        .mealDetail-body-container .mealDetailnfo-body {
            margin-top:20px;

            .row {
                margin-bottom: 5px;
                border-bottom: 1px solid #f0f0f0;
                padding-bottom: 5px;

                .col {
                    padding: 0 0;
                }
            }
        }
    }

    .closeButton {
        position: absolute;
        right: 40px;
        top: 40px;
        color: #000;
        cursor: pointer;
    }

    .mealDetail-footer-container {
        padding: 20px 0px;
        background:white;
        flex: 0 1 80px;

        button span {
            vertical-align: top;
        }
    }
}

#mealSubstitutions {
    #substition-warning {
        margin-bottom: 0px;
    }

    .mealDetail-body-container {
        overflow-y:auto;

        .substituteMeals-container {

            padding: 2px;

            .substituteMeal {
                vertical-align: middle;
                line-height: 50px;

                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
                padding: 10px;
                margin-right: 5px;

                .btn-floating {
                    margin-right:10px;
                }
            }
        }
    }
}

.card.customer.nutritionSchedule {
    img.material-icons.large {
        width: 96px;
        height: 96px;
        margin: 0 auto;
        border-radius:0px;
    }
}

.nutrition-week-day-title {
    cursor: pointer;

    h3 {
        display: inline-block;
    }
}

.nutrition-week-day-meal {
    cursor:pointer;
}

.nutrition-week-day-meal:hover > .row {
    background:#f2f2f2;
}

.nutrition-week-day-meal .row {
    margin-bottom:0px;
}

.nutrition-week-day-meal-disabled .row {
    margin-bottom:0px;
}

.mealDetailnfo .hidden {
    display:none;
}

.nutrition-week-day-recipe-image {
    display: flex;
    flex-direction: column;

    figure {
        height: 100%;

        .recipe-image {
            background-size:cover;
            background-position: center center;
            display: block;
            height: 400px;
            height:100%;
            width: 100%;
        }
    }
}

.recipe-image-container {
    margin:0px;
}

.nutrition-week-day-meal:first-child {
    .row {
        border-top: 1px #e8e8e8 solid;
    }
}

.nutrition-week-day-meal {
    .row {
        border-bottom: 1px #e8e8e8 solid;
        padding-bottom: 5px;

        padding-left: 0px;
        padding-right: 0px;

        .col {
            margin:0px;
            line-height: 1.4;
            padding-left: 0px;
            padding-right: 0px;
        }

        .col.right {
            text-align:right;
        }
    }
}

.nutrition-week-day-row {
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 0;

    h3 {
        font-size:2.0rem;
        margin-bottom: 0px;
    }

    .total-row .col {
        padding-right:0px;
    }

    .row {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .nutrition-week-day-nutritiontotals .row {
        margin-left:0px;
        margin-right:0px;
        margin-bottom:0px;
    }

    .nutrition-week-day-col-divide {
        padding-left: 0px;
        padding-right: 0px;

        .row {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .meal-title {
        color: $dark-grey;
    }
    .meal-value {
        color: black;
    }

    .substituteMealRow {
        .meal-title {
            font-weight: bold;
        }
    }
}

.row .recipe-row {
    margin: 0;
    border-top: 1px #e8e8e8 solid;
}

.nutrition-week-day-nutritiontotals {
    .row {
        height: auto;
        overflow: visible;

        > .col {
            line-height: 1.4
        }

        .meal-title {
            line-height: 1.4
        }
        .meal-value {
            line-height: 1.4
        }
    }
}

.cardHeader {
    > * {
        padding: 28px 12px 0 0;
    }

    > div {
        display: inline-block;
    }

    h5 {
        margin: 0;
        font-size: 24px;
    }

    .buttonContainer {
        @extend .right;

        .btn.grey {
            background-color: $light-grey !important;
            color: $dark-grey;
        }

        .btn {
            padding: 0 0.6rem;
        }
    }
}

#dayDetailInfo .mealDetailnfo, #mealDetailInfo .mealDetailnfo {
    padding: 0px;

    .mealDetailinfo-container {
        padding: 40px 40px 0px 40px;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        height: 100%;

        .card-content {
            padding: 12px;

            * {
                vertical-align: middle;
            }

            *:not(:last-child) {
                margin-right: 12px;
            }

            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    .recipe-image-row {
        height: 100%;
        width: 100%;
        margin: 0px;

        .recipe-image-col {
            height: 100%;
            margin: 0px;
            padding: 0px;

            .recipe-image-container {
                margin: 0px;
                height: 100%;
                width: 100%;

                .recipe-image {
                    height: 100%;
                    width: 100%;
                    background-size: cover;
                    display: block;
                    background-position: center center;
                }
            }
        }
    }
}

#mealSubstitutions .mealDetailnfo {
    padding: 0px;

    .mealDetailinfo-container {
        padding: 40px;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        height: 100%;

        .input-field.col {
            margin-left: -0.75rem;
            margin-right: -0.75rem;
        }
    }
}

@media (max-height: 500px) {
    #dayDetailInfo, #mealDetailInfo, #mealSubstitutions {
        .mealDetailnfo {
            .recipe-image-row {
                .recipe-image-col {
                    height:0%;
                    visibility: collapse;
                }
            }
        }
    }

    #dayDetailInfo, #mealDetailInfo {
        .mealDetailnfo {
            .mealDetailinfo-container {
                height:100%;
            }
        }
    }
}

@media #{$medium-and-down} and (min-height: 501px)  {
    #dayDetailInfo, #mealDetailInfo, #mealSubstitutions {
        .mealDetailnfo {
            .recipe-image-row {
                .recipe-image-col {
                    height:15%;
                }
            }
        }
    }

    #dayDetailInfo, #mealDetailInfo {
        .mealDetailnfo {
            .mealDetailinfo-container {
                height:85%;
            }
        }
    }
}

@media #{$medium-and-down}{
    #dayDetailInfo, #mealDetailInfo, #mealSubstitutions {
        top: 0px !important;
        bottom: 0px;
        position: fixed;
        transform: none !important;
        width:100%;
        overflow-y:auto;

        .mealDetailnfo {
            height:100%;

            h2 {
                white-space:pre-wrap;
                word-wrap:break-word;
                font-size: 1.625em;
            }

            .userInfo.variableName {
                display: none;
            }
        }
    }

    #mealSubstitutions {
        .mealDetailnfo {
            .mealDetailinfo-container {
                padding:20px;
                height:100%;
            }

            .closeButton {
                right:10px;
                top:10px;
            }
        }
    }
}


@media #{$medium-and-down} {
    .nutrition-week-day-detail {
        padding-right:0px;
    }

    .container .row.nutrition-week-day-row {
        margin: 0px;

        .nutrition-week-day-recipe-image {
            padding: 0px 0px;
            margin: 0px;
        }
    }

    .nutrition-week-day-row {
        display:block;

        .total-row .col {
            margin-right:0px;
        }

        .nutrition-week-day-recipe-image {
            display:block;

            figure.recipe-image-container {
                height: auto;

                .recipe-image {
                    height: 250px;
                    background-position: center center;
                }
            }
        }
    }
}

@media #{$small-and-down} {
    .nutritionSchedule {
        position:relative;
    }

    .nutrition-week-day-nutritiontotals {
        margin-top:20px;

        .row > .col {
            padding:0px 0px;
        }
    }

    .nutrition-week-day-row .total-row .col {
        padding-right: 0.75rem;
    }

    .nutrition-week-day-meal {
        .row {
            margin-right:0px;
        }
    }

    .recipe-image {
        height: 250px;
        background-position: center center;
    }
}

.nutritionScheduleWeek-print.printPage-box {
    cursor: default;
    * {
        cursor: default;
    }

    .loaderContainer[data-fixed='fixed'] {
        position: fixed;
        top: 0px;
        left: 0px;
    }

    .substituteMeal {
        display: none;
    }

    .nutrition-week-day-meal:hover > .row {
        background:none;
    }

    .cardHeader {
        .buttonContainer {
            display: none;
        }
    }
}

@media print {
    .nutritionScheduleWeek-print.printPage-box {
        box-shadow: none;
    }

    .nutrition-week-day-detail {
        box-shadow: none;
        page-break-inside: avoid;
        padding: 10px 0px;
        border-top:0px none transparent;
        margin-bottom: 0px;

        .row {
            margin-bottom:0px;
        }
        .substituteMeal {
            display: none;
        }
    }

    .shoppinglist {
        width:75%;
        page-break-inside: avoid;

        .shoppinglist-container {
            box-shadow: none;
            padding: 10px 0px;
        }
    }
}