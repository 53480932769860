.modal {
  width: 90%;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto;
  -webkit-overflow-scrolling:touch;

  img {
    max-width: 100%;
  }

  &.ultrawide {
    max-width: 1200px;
    max-height: 100%;
  }

  &.wide {
    max-width: 900px;
  }

  &.changeExercise {
    max-width: 640px;
  }

  &.medium {
    max-width: 700px;
  }

  .modal-content + .modal-footer {
    margin-top: -8px;
    padding: 0 24px;
  }

  .modal-footer {
    overflow: hidden;
    margin-bottom:12px;

    .row {
      margin-bottom:0px;
    }

    button + button {
      margin-right: 16px !important;
    }
  }
}

.card {
  &.primary {
    background-color: $primary-color;
  }
  &.red,&.primary {
    box-shadow: none;
  }
}

.suffixInput {
  position:absolute;
  right:0.75rem;
  top:0.8rem;
}

#addMeasurement {
  h5 {
    padding: 0 0.75rem;
  }
}

form {
  .goalModalPart {
    a.info {
      position: static;
      font-size: 12px;
      display: inline-block;
      margin-top: 0.5rem;
    }
  }
}

@media #{$medium-and-up} {
  .modal {
    .modal-content .row > .col-no-pad:first-child {
      padding-left:0px;
    }

    .modal-content .row > .col-no-pad:last-child {
      padding-right:0px;
    }

    .modal-footer .row > .col-no-pad:first-child {
      padding-left:0px;
    }

    .modal-footer .row > .col-no-pad:last-child {
      padding-right:0px;
    }
  }
}

// Flex display mode divides content by every child, including ::before and ::after
// This removes the ::after elemement, which gets added by the Materialize `row` class.
.row.no-after:after {
  display: none;
}

#calculateKcalDaily {
  .calculateKcalHeader {
    @media #{$small-and-down} {
      &.row {
        margin-bottom: auto;
      }

      .subtext {
        display: none;
      }
    }
  }

  .calculateKcalFooter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;

    margin-top: -16px;

    > * {
      margin-top: 16px;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
    }

    @media #{$small-and-down} {
      .medium-filler {
        display: none;
      }

      .small-filler {
        flex-grow: 1;
      }

      .buttonContainer {
        flex-grow: 1;

        .btn {
          width: 130px;
          font-size: 10px;
          font-weight: bold;
        }
      }
    }

    @media #{$medium-and-up} {
      .medium-filler {
        flex-grow: 1;
      }

      .small-filler {
        display: none;
      }

      .btn {
        margin-left: 16px;
      }
    }
  }
}

.adviceKcalDailyContainer {
  display: flex;
  align-items: center;

  .input-field {
    width: 100%;
  }

  .btn {
    margin-left: 16px;
  }
}

#editClient {
  @media #{$small-and-down} {
    .image-inputs-col {
        padding: 0px;
      }

    .modal-footer {
      height: auto;
    }
  }
}

.goalModalPart {
  @media #{$small-and-down} {
    .collapsible-header {
      span {
        font-size: 0.8em;
      }
    }

    .collapsible-body {
      padding: 0px;
    }
  }
}