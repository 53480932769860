nav.navbar {
  background-color: $secondary-color;
  box-shadow: none;
  border-bottom: 5px solid $primary-color;
  box-sizing: content-box;

  .brand-logo {
    margin-top: 8px;
    position: static;
    float: left;

    img {
      width: 260px;
    }
  }

  ul {
    a {
      font-size: 16px;
      font-weight: 400;
      padding: 0 1vw;

      .material-icons {
        font-size: 24px;
      }
    }
  }

  i.right {
    margin-left: 8px;
  }
  i.left {
    margin-right: 8px;
  }

  .profilePic {
    height: 40px;
    width: 40px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
  }

  .searchContainer {
    display: inline-block;
    width: 45%;
    float: left;
    margin-left: 32px;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $medium-grey;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $medium-grey;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: $medium-grey;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $medium-grey;
    }

    .prefieldIcon {
      float: left;
    }

    .input-field input[type=search]{
      height: 80px;
      font-size: 16px;
      border-right: 1px solid $secondary-color;
      margin-left: 1rem;
      padding-left: 1rem;

      &:focus {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2e2e2e+0,3b3b3b+100 */
        background: $secondary-color-light; /* Old browsers */
        background: -moz-linear-gradient(left, $secondary-color 0%, $secondary-color-light 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $secondary-color 0%,$secondary-color-light 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $secondary-color 0%,$secondary-color-light 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e2e2e', endColorstr='#3b3b3b',GradientType=0 ); /* IE6-9 */
        color: #FFF !important;
        border-right: 1px solid $medium-grey;
      }
    }

    .input-field input[type=search]:focus + label i, .input-field input[type=search]:focus ~ .mdi-navigation-close, .input-field input[type=search]:focus ~ .material-icons {
      color: #FFF;
    }

    label .material-icons {
      color: #FFFFFF;

    }
  }
}

@media #{$medium-and-down} {
  nav.navbar {

    .nav-wrapper {
      position: static;
    }

    ul {
      a {
        padding: 0 3vw;
      }
    }

    .brand-logo {
      left: 0;
      transform: none;
      max-width: 136px;
      margin-top: 0;

      img {
        width: 100%;
      }
    }

    .searchContainer {
      display: block;
      position: absolute;
      top:60px;
      height: 0;
      border-right: none;
      width: 100%;
      float: none;
      margin-left: 0;
      background-color: $secondary-color;
      left: 0;
      z-index: 1;
      transition: height 0.2s ease-in-out;
      overflow: hidden;

      &.active {
        height: 60px;
      }

      .input-field input[type=search] {
        border-right: none;
        height: 60px;
      }
    }

    .button-collapse {
      padding-right: 0;
    }
  }
}

.userName {
  display: inline-block;
  width: 167px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  padding-right: 8px;
  @media #{$extra-large-and-up} {
    width: auto;
    overflow: visible;
    text-overflow: ellipsis;
    text-align: right;
    padding-right: 0;
  }
  @media #{$medium-and-down} {
    width: auto;
  }
}


.transparent nav.navbar {
  background-color: transparent;
  border-bottom: transparent;
  .searchContainer {
    display: none;
  }
  #nav-mobile a {
    display: none;
  }
  #nav-mobile a[data-activates] {
    display: inline-block;
  }
}