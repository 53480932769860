.btn-floating {

  height: 50px;
  width: 50px;

  .material-icons, .icon {
    font-size: 24px;
    line-height: 50px;
    color: #000;
  }

  &.btn-large {
    height: 68px;
    width: 68px;

    .material-icons, .icon {
      font-size: 40px;
      line-height: 68px;
      color: #000;
    }
  }
}

.actions {
  height: 48px;
  position: relative;

  .btn-floating {
    float: right;
    margin-top: -26px;
    &.btn-large {
      margin-top: -36px;
    }
  }
  .btn-floating + .btn-floating {
    margin-right: 16px;
  }
}
.filters {
  margin-top: -56px;
  .row {
    margin: 0;
    margin-left: -12px;

    .select-wrapper input.select-dropdown {
      border-bottom: none;
      text-indent: 16px;
      color: $medium-dark-grey;
    }
    .select-wrapper span.caret {
      left: 0;
      right: auto;
      color: $medium-dark-grey;
    }
  }
}
.dropdownSelecter {
  color: $medium-grey;
  margin-right: 16px;
  &:last-child{
    margin-right: 0;
  }
  i {
    vertical-align: middle;
  }
}

.addLink-disabled {
  @extend .addLink;
  cursor: default !important;
}

.addLink {
  display: block;
  width: 100%;
  height: 314px;
  margin-top: 8px;
  border: 1px dashed $medium-grey;
  position: relative;
  cursor: pointer;

  .floating {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%,-50%);
  }

  .btn-floating {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    background-color: #DFDFDF;
  }

  .material-icons, .icon {
    font-size: 42px;
    line-height: 50px;
    color: #000;
  }

  &:hover {
    .btn-floating {
      background-color: $primary-color;
      &:hover {
        background-color: $primary-color;
      }
    }
  }

  .caption {
    position: absolute;
    bottom: 0;
    height: 42%;
    font-size: 24px;
    color: #000 !important;
    text-align: center;
    display: inline-block;
    width: 60%;
    left: 50%;
    transform: translatex(-50%);
    line-height: 28px;
  }
  @media #{$small-and-down} {
    height: 176px;
    .btn-floating {
      top: 32%;
    }
    .caption {
      height: 44%;
    }
  }

  &.horizontal {

    height: auto;
    padding: 16px 24px;

    .btn-floating {
      position: static;
      left: 0;
      top: 0;
      transform: none;
      height: 32px;
      width: 32px;

      .material-icons, .icon {
        font-size: 20px;
        line-height: 32px;
      }
    }
    .caption {
      position: static;
      transform: none;
      left: 0;
      height: auto;
      width: auto;
      margin-left: 12px;
      vertical-align: middle;
      font-size: 16px;
    }
  }
}

.clientName .material-icons {
  margin-left: 20px;
  font-size: 28px;
  color: $primary-color;
}