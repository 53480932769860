.invoice-box, .printPage-box{
  width:800px;
  margin:auto;
  padding:30px;
  //border:1px solid #eee;
  box-shadow:0 0 10px rgba(0, 0, 0, .15);
  font-size:16px;
  line-height:24px;
  font-family:'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color:#555;

  table{
    width:100%;
    line-height:inherit;
    text-align:left;

    td{
      padding:5px;
      vertical-align:top;
    }

    tr {
      td:nth-child(2){
        text-align:right;
      }

      &.top {
        > td {
          width: 100%;
        }

        table td{
          padding-bottom:20px;
        }


        &.title {
          font-size:45px;
          line-height:45px;
          color:#333;
        }
      }

      &.information table td{
        padding-top: 0;
        padding-bottom:40px;
        vertical-align: top;
      }

      &.heading td{
        background:#eee;
        border-bottom:1px solid #ddd;
        font-weight:bold;
      }

      &.details td{
        padding-bottom:20px;
      }

      &.item td{
        border-bottom:1px solid #eee;
      }

      &.item.last td{
        border-bottom:none;
      }

      &.total td:nth-child(2){
        border-top:2px solid #eee;
        font-weight:bold;
      }

      @media #{$small-and-down} {
        &.top table td{
          width:100%;
          display:block;
          text-align:center;
        }
        &.information table td{
          width:100%;
          display:block;
          text-align:center;
        }
      }
    }
  }

  h6 {
    margin-bottom: 0;
  }
}

.printPage-box {
  font-size: 11px;
  line-height: 16px;
}

