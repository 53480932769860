.fmfCropper {
  width: 100%;

  &.image .cropperContainer {
    display: none;
  }

  &.image .imageContainer {
    display: block;
  }

  &.cropper .cropperContainer {
    display: block;
  }
  &.cropper .imageContainer {
    display: none;
  }

  .cropperContainer {
    canvas {
      width: 100% !important;
      height: 100% !important;
    }

    .btn.accept {
      float: right;
    }
  }

  input+.thumb {
    display: none !important;
  }
}